.left-menu-layer-control-container {
    details {
        summary {
            background-color: black;
        }
    }

    .base-map-header-summary {

        .MuiAccordionSummary-expandIcon {
            position: absolute;
            left: 0px
        }

        .add-button-icon {
            right: 14px;
        }
    }

    .smooth-dnd-container.vertical {
        width: 100%;
        min-height: auto;
        min-width: auto;
    }

    .icon-layer {
        color: green;
    }

    .icon-layers-custom {
        left: 0px;
    }

    input[type=checkbox] {
        cursor: pointer;
    }

    .MuiAccordionSummary-root {
        &.Mui-expanded {
            min-height: 40px;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;

        &.Mui-expanded {
            margin: 10px 0 0 0;
        }
    }
}