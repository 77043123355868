.list-items-container {
    height: calc(100vh - 440px);
    overflow: auto;

    .container-list-limited-height {
        height: calc(100vh - 490px);
        overflow: auto;

        .custom-col {
            display: flex;
            align-items: center;
        }

        .custom-row {
            border-top: 1px solid black;
            background-color: white;
            cursor: move;
        }

        .custom-row-drag {
            border: 1px solid black;
        }
    }
}
.padding-left{
    padding-left: 20px;
}