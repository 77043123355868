.sidebar {
    .is-active {
        font-size: 0.9rem;
        font-weight: 800;
        color: white !important;
    }

    .sub-link-adminstrator-active {
        background-color: #80808031;
    }

    .nav-item {
        .nav-link {
            .fa-chevron-right {
                display: none;
            }

            .fa-chevron-down {
                display: block;
            }

            &.collapsed {
                .fa-chevron-right {
                    display: block;
                }

                .fa-chevron-down {
                    display: none;
                }
            }
        }
    }
}