.logo {
  width: 70px;
  height: 70px;
  object-fit: contain;
}

.clearTrash{
    font-size:12pt;
}
.MuiDropzoneArea-root {
  min-height: auto !important;
  padding: 15px 0;

  .MuiDropzoneArea-text {
    margin-top: 0;
  }

  .MuiGrid-container {
    width: 100%;
    margin: 0;

    .MuiDropzonePreviewList-imageContainer {
      width: 100%;
      padding-bottom: 15px;
      max-width: 100%;
      flex-basis: 100%;

      .MuiDropzonePreviewList-image {
        width: 100%;
        height: 22.5rem;
        object-fit: cover;
      }

      .MuiDropzonePreviewList-removeButton {
        top: 10px;
        right: 15px;
      }
    }
  }
}
